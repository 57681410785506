

























































import { Vue, Component, Watch } from "vue-property-decorator";
import { api_settings, settings } from "@/http/settings";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
@Component({
  components: {
    PageHeader,
  },
})
export default class extends Vue {
  info: settings = {
    id:"",
    create_time:"",
    update_time:"",
    put_num:2,
    get_num:2,
    refund_ratio:0
  };
  rules = {
    put_num: [{ required: true, message: "请填写入库拍照数量" }],
    get_num: [{ required: true, message: "请填写出库拍照数量" }],
  };
  async submit() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    await (this.$refs["form2"] as ElForm | undefined)?.validate();
    if (this.id) {
      await api_settings.edit(this.info);
    } 
    // else {
    //   if(this.info.start_time==""&&this.info.fish_time==""){
    //     delete this.info.start_time
    //     delete this.info.fish_time
    //   }
    //   await api_settings.create(this.info);
    // }
    // this.$message.success("提交成功");
    this.$router.go(-1);
  }

  async get_info() {
    this.info = await api_settings.get_info(this.id as number);
  }
  get id(): undefined | number {
    return this.$route.query.id as any;
  }


  created() {
    if (this.id === undefined) return;
    this.get_info();
  }
}
